import React, { useState, useEffect } from "react";
import { GlobalHotKeys } from "react-hotkeys";
import Amplify from "aws-amplify";
import Predictions, {
  AmazonAIPredictionsProvider,
} from "@aws-amplify/predictions";
import awsmobile from "../aws-exports";
import { VerbForm } from "../utils/responseParsing";
import { fetchVerb } from "../utils/verbLoader";
import { verbTextForSpeech } from "../utils/verbHelpers";
Amplify.addPluggable(new AmazonAIPredictionsProvider());
Amplify.configure(awsmobile);

function Verb(props: any) {
  let practiceTenses = props.tenses;
  if (practiceTenses.length === 0) {
    practiceTenses = ["present"];
  }

  const [input, setInput] = useState("");
  const [verb, setVerb] = useState({ config: {} });
  const [correct, setCorrect] = useState("");
  const [score, setScore] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    loadVerb();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadVerb = async () => {
    setCorrect("");
    setInput("");
    let verbResponse: VerbForm = await fetchVerb(total, practiceTenses);
    setVerb(verbResponse);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };

  const handleBtnClick = (e: any) => {
    e.preventDefault();
    setInput(input + (e.target as HTMLInputElement).value);
  };

  const handleInputChange = (e: any) => {
    // strip shortcut numbers out
    const numbers = e.target.value.match(/(\d+)/);
    let newInput = e.target.value;
    if (numbers) {
      newInput = newInput.replace(numbers[0], "");
    }
    setInput(newInput);
  };

  const validateAnswer = (e: any) => {
    e.preventDefault();
    if (input.toLowerCase() === (verb as VerbForm).expectedAnswer) {
      setCorrect("y");
      let newScore = score + 1;
      setScore(newScore);
    } else {
      setCorrect("n");
    }

    let newTotal = total + 1;
    setTotal(newTotal);
  };

  const playTextInFrench = async (conjugated: boolean) => {
    let result = await Predictions.convert({
      textToSpeech: {
        source: {
          text: verbTextForSpeech(verb as VerbForm, conjugated),
        },
      },
    });

    var audio = new Audio();
    audio.src = result.speech.url;
    audio.play();
  };

  const keyMap = {
    a1: "1",
    e1: "2",
    u1: "3",
    e2: "4",
    a2: "5",
    e3: "6",
    i1: "7",
    o1: "8",
    u2: "9",
    e4: "0",
    i2: "ctrl+1",
    u3: "ctrl+2",
    ae1: "ctrl+3",
    oe1: "ctrl+4",
    c1: "ctrl+5",
  };

  const handlers = {
    a1: (event: any) => setInput(event.target.value + "à"),
    e1: (event: any) => setInput(event.target.value + "è"),
    u1: (event: any) => setInput(event.target.value + "ù"),
    e2: (event: any) => setInput(event.target.value + "é"),
    a2: (event: any) => setInput(event.target.value + "â"),
    e3: (event: any) => setInput(event.target.value + "ê"),
    i1: (event: any) => setInput(event.target.value + "î"),
    o1: (event: any) => setInput(event.target.value + "ô"),
    u2: (event: any) => setInput(event.target.value + "û"),
    e4: (event: any) => setInput(event.target.value + "ë"),
    i2: (event: any) => setInput(event.target.value + "ï"),
    u3: (event: any) => setInput(event.target.value + "ü"),
    ae1: (event: any) => setInput(event.target.value + "æ"),
    oe1: (event: any) => setInput(event.target.value + "œ"),
    c1: (event: any) => setInput(event.target.value + "ç"),
  };

  return (
    <form onSubmit={handleSubmit} className="w-full lg:w-1/2">
      <div className="bg-gray-900 mb-8 mx-4 mt-4">
        {total > 0 && (
          <div className="text-md p-2 text-center text-gray-100 bg-pink-500 mt-4">
            <p>
              Score: {score}/{total} — {Math.trunc((score / total) * 100)}%
            </p>
          </div>
        )}
        <div className="text-4xl p-6 text-center text-gray-100 bg-blue-500">
          <p
            onClick={async () => await playTextInFrench(false)}
            className="cursor-pointer"
          >
            <img
              alt="hear verb"
              className="inline-block w-3 mr-1"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAABmJLR0QA/wD/AP+gvaeTAAADMklEQVRYhe3YS6hVVRzH8c9Ry5I66s3oRVBSaGKohTroAU2yoCCHRS8lwwYFTqJJw2qaDsKI0PIm1CBo0LAIiqKswKAH9BDqSu+oi0bSvZ4G/7W7m3V2++59PefcBv5gsTn//1prf896/f9rc1qnpoUD6ucsPIA7MYmJAfU7EI3hffRS+QtXzytRSWP4CNN4GKsF5BPzCVWoDLetZO/h2QbtH8INQ+DCf8PRDLCD73ASB3H+IOGW4YMEd3+Fv+kILsduTAnYTYOAK49cFRzNAQvdjF9wHDcOCu6+mnp1gKtwSYX9CnE0TeKaYcLNBviZGKldYh2WtVqM5FfozgVuCvc2qF8HuAEfpzrjWJT570i+5/OG56bG12Zlc0u42QDhTOxL9fZV+A+Imfr3sL8Vf5iJBHmZwj0N4ZoAEtM7nupuz3yXiWj0SmH4Bj+JdfFgqbyWOtjRAi4H7GAjzqiotwSf41cszXwvJcjzig5fqOjgmeSr6rwp4Lr0e0L1Erkt+R/L7FuSfceCZDhZ0bjIdP5uCVjWJ7gbv2O/mKWyXhe7Nl9Cb+EErltguOqJ6dqMt/EUVmb+V7EGF5XsJ3AYm4YNWOg4dood/Ejm+zA912X2I7h4VIDEhpgQx1lZ36fnisz+G7qjBJyTRgm4BpeKSFJWsfZ+zuxjmBwV4DnYKxb/nsy3MT0PZ/aVODoKwLtE/ni9OO+OlHwdbMWn+KFkXyw2zaECsAp0Oj3bHtRlrRLHzAUi8346898uUq0XM/tNYse/A1+rD3U7W0LlsXg9zq6otwRfiBQrT68OilA3BreoTxam9Qf0NoBV6oiR7ekPgZeLtfpy2ThbutUGsk26VVVvXGRQaxu+z3Jx2jeFrANcayZh3a//y8bW5HuuKVwZ8lBDyDrAN3BMXPJzXSVSry/FjLZW+bpZB1kHeGEqua7EUZH1rJ8LXKFl4jtMHWTba+cWMXLHxFl5ylqK90QOWXUEzeXi/q2ZiDIQFZBVI9kEcDF+FH/ygP5MZiDq6odcmAD3ztK2g0cN8eNRoS7eFZC7xDT18PiwX9xGXbxpJvL8KeLr/0qLxMXnSf0Z82nNq/4B263og2vTNdkAAAAASUVORK5CYII="
            />
            {(verb as VerbForm).infinitive}
          </p>
          <p className="text-sm font-hairline">
            {(verb as VerbForm).translation}
          </p>
        </div>
        <div className="font-hairline p-4 text-center text-gray-100 border-b border-gray-800">
          <p className="text-xs mb-1 text-gray-500">tense</p>
          <p
            className={
              "inline-block px-1 border border-gray-800 rounded " +
              (verb as VerbForm).config.color
            }
          >
            {(verb as VerbForm).config.english}
          </p>
          <p className="text-xs">
            <i>{(verb as VerbForm).config.french}</i>
          </p>
        </div>
        <div className="font-hairline p-4 text-center text-gray-100">
          <p className="text-xs mb-1 text-gray-500">person</p>
          <p>{(verb as VerbForm).personDescription}</p>
        </div>
        {correct === "y" && (
          <div className="font-200 p-2 text-center text-gray-900 bg-green-400">
            <p>
              <span role="img" aria-label="party popper">
                🎉
              </span>
              Correct! +1 point
            </p>
            <p
              onClick={async () => playTextInFrench(true)}
              className="cursor-pointer"
            >
              <img
                alt="hear verb"
                className="inline-block w-3 mr-1"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAABmJLR0QA/wD/AP+gvaeTAAADMklEQVRYhe3YS6hVVRzH8c9Ry5I66s3oRVBSaGKohTroAU2yoCCHRS8lwwYFTqJJw2qaDsKI0PIm1CBo0LAIiqKswKAH9BDqSu+oi0bSvZ4G/7W7m3V2++59PefcBv5gsTn//1prf896/f9rc1qnpoUD6ucsPIA7MYmJAfU7EI3hffRS+QtXzytRSWP4CNN4GKsF5BPzCVWoDLetZO/h2QbtH8INQ+DCf8PRDLCD73ASB3H+IOGW4YMEd3+Fv+kILsduTAnYTYOAK49cFRzNAQvdjF9wHDcOCu6+mnp1gKtwSYX9CnE0TeKaYcLNBviZGKldYh2WtVqM5FfozgVuCvc2qF8HuAEfpzrjWJT570i+5/OG56bG12Zlc0u42QDhTOxL9fZV+A+Imfr3sL8Vf5iJBHmZwj0N4ZoAEtM7nupuz3yXiWj0SmH4Bj+JdfFgqbyWOtjRAi4H7GAjzqiotwSf41cszXwvJcjzig5fqOjgmeSr6rwp4Lr0e0L1Erkt+R/L7FuSfceCZDhZ0bjIdP5uCVjWJ7gbv2O/mKWyXhe7Nl9Cb+EErltguOqJ6dqMt/EUVmb+V7EGF5XsJ3AYm4YNWOg4dood/Ejm+zA912X2I7h4VIDEhpgQx1lZ36fnisz+G7qjBJyTRgm4BpeKSFJWsfZ+zuxjmBwV4DnYKxb/nsy3MT0PZ/aVODoKwLtE/ni9OO+OlHwdbMWn+KFkXyw2zaECsAp0Oj3bHtRlrRLHzAUi8346898uUq0XM/tNYse/A1+rD3U7W0LlsXg9zq6otwRfiBQrT68OilA3BreoTxam9Qf0NoBV6oiR7ekPgZeLtfpy2ThbutUGsk26VVVvXGRQaxu+z3Jx2jeFrANcayZh3a//y8bW5HuuKVwZ8lBDyDrAN3BMXPJzXSVSry/FjLZW+bpZB1kHeGEqua7EUZH1rJ8LXKFl4jtMHWTba+cWMXLHxFl5ylqK90QOWXUEzeXi/q2ZiDIQFZBVI9kEcDF+FH/ygP5MZiDq6odcmAD3ztK2g0cN8eNRoS7eFZC7xDT18PiwX9xGXbxpJvL8KeLr/0qLxMXnSf0Z82nNq/4B263og2vTNdkAAAAASUVORK5CYII="
              />
              {(verb as VerbForm).person}{" "}
              <b>{(verb as VerbForm).expectedAnswer}</b>
            </p>
          </div>
        )}
        {correct === "n" && (
          <div className="font-200 p-2 text-center text-gray-900 bg-red-400">
            <p>
              <span role="img" aria-label="sad face">
                😔
              </span>
              Not quite right
            </p>
            <p
              onClick={async () => playTextInFrench(true)}
              className="cursor-pointer"
            >
              <img
                alt="hear verb"
                className="inline-block w-3 mr-1"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAABmJLR0QA/wD/AP+gvaeTAAADMklEQVRYhe3YS6hVVRzH8c9Ry5I66s3oRVBSaGKohTroAU2yoCCHRS8lwwYFTqJJw2qaDsKI0PIm1CBo0LAIiqKswKAH9BDqSu+oi0bSvZ4G/7W7m3V2++59PefcBv5gsTn//1prf896/f9rc1qnpoUD6ucsPIA7MYmJAfU7EI3hffRS+QtXzytRSWP4CNN4GKsF5BPzCVWoDLetZO/h2QbtH8INQ+DCf8PRDLCD73ASB3H+IOGW4YMEd3+Fv+kILsduTAnYTYOAK49cFRzNAQvdjF9wHDcOCu6+mnp1gKtwSYX9CnE0TeKaYcLNBviZGKldYh2WtVqM5FfozgVuCvc2qF8HuAEfpzrjWJT570i+5/OG56bG12Zlc0u42QDhTOxL9fZV+A+Imfr3sL8Vf5iJBHmZwj0N4ZoAEtM7nupuz3yXiWj0SmH4Bj+JdfFgqbyWOtjRAi4H7GAjzqiotwSf41cszXwvJcjzig5fqOjgmeSr6rwp4Lr0e0L1Erkt+R/L7FuSfceCZDhZ0bjIdP5uCVjWJ7gbv2O/mKWyXhe7Nl9Cb+EErltguOqJ6dqMt/EUVmb+V7EGF5XsJ3AYm4YNWOg4dood/Ejm+zA912X2I7h4VIDEhpgQx1lZ36fnisz+G7qjBJyTRgm4BpeKSFJWsfZ+zuxjmBwV4DnYKxb/nsy3MT0PZ/aVODoKwLtE/ni9OO+OlHwdbMWn+KFkXyw2zaECsAp0Oj3bHtRlrRLHzAUi8346898uUq0XM/tNYse/A1+rD3U7W0LlsXg9zq6otwRfiBQrT68OilA3BreoTxam9Qf0NoBV6oiR7ekPgZeLtfpy2ThbutUGsk26VVVvXGRQaxu+z3Jx2jeFrANcayZh3a//y8bW5HuuKVwZ8lBDyDrAN3BMXPJzXSVSry/FjLZW+bpZB1kHeGEqua7EUZH1rJ8LXKFl4jtMHWTba+cWMXLHxFl5ylqK90QOWXUEzeXi/q2ZiDIQFZBVI9kEcDF+FH/ygP5MZiDq6odcmAD3ztK2g0cN8eNRoS7eFZC7xDT18PiwX9xGXbxpJvL8KeLr/0qLxMXnSf0Z82nNq/4B263og2vTNdkAAAAASUVORK5CYII="
              />
              {(verb as VerbForm).person}{" "}
              <b>{(verb as VerbForm).expectedAnswer}</b>
            </p>
          </div>
        )}
      </div>
      <div className="bg-transparent p-4">
        <div className="flex flex-row justify-center items-center">
          <div className="text-3xl text-center text-gray-100 content-center">
            <p>{(verb as VerbForm).person}</p>
          </div>
          <div className="ml-4 text-center text-gray-900 content-center">
            <GlobalHotKeys keyMap={keyMap} handlers={handlers}>
              <input
                className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
                value={input}
                onChange={handleInputChange}
                type="text"
              />
            </GlobalHotKeys>
          </div>
        </div>
      </div>
      <div className="bg-transparent p-4 mb-8">
        <div className="flex flex-row justify-center items-center">
          {correct === "" && (
            <button
              type="submit"
              onClick={validateAnswer}
              className="bg-blue-500 text-xl hover:bg-gray-900 text-gray-100 font-bold py-2 px-4 mx-4 border border-blue-700 rounded"
            >
              Submit
            </button>
          )}
          {correct !== "" && (
            <button
              type="submit"
              onClick={loadVerb}
              className="bg-blue-500 text-xl hover:bg-gray-900 text-gray-100 font-bold py-2 px-4 mx-4 border border-blue-700 rounded"
            >
              Next
            </button>
          )}
        </div>
      </div>
      <div className="bg-transparent p-4">
        <div className="flex flex-row">
          <div className="text-center">
            <button
              type="button"
              onClick={handleBtnClick}
              className="bg-blue-500 hover:bg-gray-900 text-gray-100 font-bold py-2 px-4 mx-4 my-4 border border-blue-700 rounded"
              value="à"
            >
              à
            </button>
            <button
              type="button"
              onClick={handleBtnClick}
              className="bg-blue-500 hover:bg-gray-900 text-gray-100 font-bold py-2 px-4 mx-4 my-4 border border-blue-700 rounded"
              value="è"
            >
              è
            </button>
            <button
              type="button"
              onClick={handleBtnClick}
              className="bg-blue-500 hover:bg-gray-900 text-gray-100 font-bold py-2 px-4 mx-4 my-4 border border-blue-700 rounded"
              value="ù"
            >
              ù
            </button>
            <button
              type="button"
              onClick={handleBtnClick}
              className="bg-blue-500 hover:bg-gray-900 text-gray-100 font-bold py-2 px-4 mx-4 my-4 border border-blue-700 rounded"
              value="é"
            >
              é
            </button>
            <button
              type="button"
              onClick={handleBtnClick}
              className="bg-blue-500 hover:bg-gray-900 text-gray-100 font-bold py-2 px-4 mx-4 my-4 border border-blue-700 rounded"
              value="â"
            >
              â
            </button>
            <button
              type="button"
              onClick={handleBtnClick}
              className="bg-blue-500 hover:bg-gray-900 text-gray-100 font-bold py-2 px-4 mx-4 my-4 border border-blue-700 rounded"
              value="ê"
            >
              ê
            </button>
            <button
              type="button"
              onClick={handleBtnClick}
              className="bg-blue-500 hover:bg-gray-900 text-gray-100 font-bold py-2 px-4 mx-4 my-4 border border-blue-700 rounded"
              value="î"
            >
              î
            </button>
            <button
              type="button"
              onClick={handleBtnClick}
              className="bg-blue-500 hover:bg-gray-900 text-gray-100 font-bold py-2 px-4 mx-4 my-4 border border-blue-700 rounded"
              value="ô"
            >
              ô
            </button>
            <button
              type="button"
              onClick={handleBtnClick}
              className="bg-blue-500 hover:bg-gray-900 text-gray-100 font-bold py-2 px-4 mx-4 my-4 border border-blue-700 rounded"
              value="û"
            >
              û
            </button>
            <button
              type="button"
              onClick={handleBtnClick}
              className="bg-blue-500 hover:bg-gray-900 text-gray-100 font-bold py-2 px-4 mx-4 my-4 border border-blue-700 rounded"
              value="ë"
            >
              ë
            </button>
            <button
              type="button"
              onClick={handleBtnClick}
              className="bg-blue-500 hover:bg-gray-900 text-gray-100 font-bold py-2 px-4 mx-4 my-4 border border-blue-700 rounded"
              value="ï"
            >
              ï
            </button>
            <button
              type="button"
              onClick={handleBtnClick}
              className="bg-blue-500 hover:bg-gray-900 text-gray-100 font-bold py-2 px-4 mx-4 my-4 border border-blue-700 rounded"
              value="ü"
            >
              ü
            </button>
            <button
              type="button"
              onClick={handleBtnClick}
              className="bg-blue-500 hover:bg-gray-900 text-gray-100 font-bold py-2 px-4 mx-4 my-4 border border-blue-700 rounded"
              value="æ"
            >
              æ
            </button>
            <button
              type="button"
              onClick={handleBtnClick}
              className="bg-blue-500 hover:bg-gray-900 text-gray-100 font-bold py-2 px-4 mx-4 my-4 border border-blue-700 rounded"
              value="œ"
            >
              œ
            </button>
            <button
              type="button"
              onClick={handleBtnClick}
              className="bg-blue-500 hover:bg-gray-900 text-gray-100 font-bold py-2 px-4 mx-4 my-4 border border-blue-700 rounded"
              value="ç"
            >
              ç
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

export default Verb;

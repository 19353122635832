import React from "react";
import Amplify from "aws-amplify";
import Start from "./components/Start";
import awsmobile from "./aws-exports";
import { configure } from "react-hotkeys";
Amplify.configure(awsmobile);

configure({ ignoreTags: ["select", "textarea"] });
function App() {
  return (
    <div className="App">
      <div className="h-screen">
        <div className="container mx-auto flex flex-col justify-center items-center">
          <Start />
          <div className="text-center text-gray-100 py-6">
            <p className="text-xs">
              Created by{" "}
              <a
                href="https://www.kylegalbraith.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-gray-900"
              >
                Kyle Galbraith
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://3soh7xgawfay5noj5txqf2uyxq.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cognito_identity_pool_id": "us-west-2:6266603f-7154-4898-9db2-6d80f5ab834d",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_9UITaquTR",
    "aws_user_pools_web_client_id": "3l8f8hqvejhdocg8fo8vc1rmnr",
    "oauth": {},
    "predictions": {
        "convert": {
            "speechGenerator": {
                "region": "us-west-2",
                "proxy": false,
                "defaults": {
                    "VoiceId": "Celine",
                    "LanguageCode": "fr-FR"
                }
            }
        }
    }
};


export default awsmobile;

/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getVerb = /* GraphQL */ `
  query GetVerb($id: ID!) {
    getVerb(id: $id) {
      id
      infinitive
      translation
      conjugations {
        name
        forms {
          person
          form
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const listVerbs = /* GraphQL */ `
  query ListVerbs(
    $id: ID
    $filter: ModelVerbFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVerbs(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        infinitive
        translation
        conjugations {
          name
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVerbByInfinitive = /* GraphQL */ `
  query GetVerbByInfinitive(
    $infinitive: String
    $sortDirection: ModelSortDirection
    $filter: ModelVerbFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getVerbByInfinitive(
      infinitive: $infinitive
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        infinitive
        translation
        conjugations {
          name
          forms {
            person
            form
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

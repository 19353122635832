export const COMMON_VERBS: string[] = [
  "aller",
  "être",
  "aimer",
  "acheter",
  "apporter",
  "mélanger",
  "recevoir",
  "boire",
  "réfléchir",
  "adorer",
  "baisser",
  "courir",
  "écrire",
  "avoir",
  "emporter",
  "revoir",
  "savoir",
  "manger",
  "sortir",
  "vouloir",
  "éviter",
  "exiger",
  "rejoindre",
  "expliquer",
  "lever",
  "produire",
  "danser",
  "payer",
  "tuer",
  "laver",
  "tirer",
  "accompagner",
  "dire",
  "lire",
  "préparer",
  "travailler",
  "voir",
  "voyager",
  "faillir",
  "montrer",
  "étudier",
  "brancher",
  "coucher",
  "déclarer",
  "faire",
  "marcher",
  "mettre",
  "nager",
  "penser",
  "regarder",
  "réussir",
  "élever",
  "manquer",
  "apprendre",
];

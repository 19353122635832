import { VerbForm } from "./responseParsing";

export function verbTextForSpeech(verb: VerbForm, conjugated: boolean): string {
  if (conjugated) {
    if (verb.person === "je/j" && smooshFirstPerson(verb)) {
      return `j'${verb.expectedAnswer}`;
    } else if (verb.person === "je/j" && !smooshFirstPerson(verb)) {
      return `je ${verb.expectedAnswer}`;
    } else if (isIlElleCase(verb)) {
      return `il ${verb.expectedAnswer}`;
    } else {
      return `${verb.person} ${verb.expectedAnswer}`;
    }
  }

  return verb.infinitive;
}

function smooshFirstPerson(verb: VerbForm): boolean {
  const collapseLetters = [
    "h",
    "a",
    "e",
    "i",
    "o",
    "u",
    "à",
    "è",
    "ù",
    "é",
    "â",
    "ê",
    "î",
    "ô",
    "û",
    "ë",
    "ï",
    "ü",
  ];
  return collapseLetters.includes(verb.expectedAnswer[0]);
}

function isIlElleCase(verb: VerbForm): boolean {
  return verb.person === "ils/elles" || verb.person === "il/elle";
}

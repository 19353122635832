import { API, graphqlOperation } from "aws-amplify";
import { getVerbByInfinitive, getVerb } from "../graphql/queries";
import { COMMON_VERBS } from "./commonVerbs";
import { parseVerbResponse, VerbForm } from "./responseParsing";

export async function fetchVerb(
  total: number,
  practiceTenses: string[]
): Promise<VerbForm> {
  if (Math.round(Math.random()) === 1) {
    let randomIdx = Math.floor(Math.random() * COMMON_VERBS.length + 0);
    const commonVerb = COMMON_VERBS[randomIdx];
    console.log(commonVerb);
    const result = await API.graphql(
      graphqlOperation(getVerbByInfinitive, { infinitive: commonVerb })
    );
    return parseVerbResponse(result, practiceTenses);
  } else {
    const verbId = Math.floor(Math.random() * 2432 + 0);
    const result = await API.graphql(graphqlOperation(getVerb, { id: verbId }));
    return parseVerbResponse(result, practiceTenses);
  }
}

import { TENSE_CONFIG, PERSON_DESCRIPTIONS } from "./config";

export type VerbForm = {
  infinitive: string;
  translation: string;
  config: {
    english: string;
    french: string;
    color: string;
  };
  person: string;
  personDescription: string;
  expectedAnswer: string;
};

export function parseVerbResponse(
  graphqlResponse: any,
  practiceTenses: string[]
): VerbForm {
  const verbObj =
    graphqlResponse.data.getVerb ||
    graphqlResponse.data.getVerbByInfinitive.items[0];
  const infinitive = verbObj.infinitive;

  const conjugations = verbObj.conjugations.filter((e: any) =>
    filterToPracticeTenses(e, practiceTenses)
  );
  const conjugation =
    conjugations[Math.floor(Math.random() * conjugations.length + 0)];

  const tense = conjugation.name;
  const forms = conjugation.forms.filter(filterEmptyObject);
  const conjugationForm = forms[Math.floor(Math.random() * forms.length + 0)];
  const expectedAnswer = conjugationForm.form;
  const person: string = conjugationForm.person;

  return {
    infinitive: infinitive,
    translation: verbObj.translation,
    config: TENSE_CONFIG[tense],
    person: person === "je" ? "je/j" : person,
    personDescription: PERSON_DESCRIPTIONS[person],
    expectedAnswer: expectedAnswer,
  };
}

function filterEmptyObject(element: any) {
  return element.form !== "{}";
}

function filterToPracticeTenses(element: any, practiceTenses: string[]) {
  return practiceTenses.includes(element.name);
}

export const PERSON_DESCRIPTIONS: {
  [key: string]: string;
} = {
  je: "Singular, 1st person",
  tu: "Singular, 2nd person",
  "il/elle": "Singular, 3rd person",
  nous: "Plural, 1st person",
  vous: "Plural, 2nd person",
  "ils/elles": "Plural, 3rd person",
};

export const TENSE_CONFIG: {
  [key: string]: {
    english: string;
    french: string;
    color: string;
  };
} = {
  present: {
    english: "Present",
    french: "Présent",
    color: "",
  },
  futur: {
    english: "Future",
    french: "Futur Simple",
    color: "bg-pink-600",
  },
  imparfait: {
    english: "Imperfect",
    french: "Imparfait",
    color: "bg-purple-600",
  },
  passe_compose: {
    english: "Past",
    french: "Passé Compose",
    color: "bg-green-600",
  },
  conditionnel_present: {
    english: "Conditional",
    french: "Conditionnel",
    color: "bg-yellow-500",
  },
  imperatif_present: {
    english: "Imperative",
    french: "Imperatif",
    color: "bg-red-600",
  },
  subjonctif_present: {
    english: "Subjunctive",
    french: "Subjonctif",
    color: "bg-gray-600",
  },
  subjonctif_imparfait: {
    english: "Imperfect Subjunctive",
    french: "Subjonctif Imparfait",
    color: "bg-teal-600",
  },
  passe_simple: {
    english: "Simple Past",
    french: "Passé Simple",
    color: "bg-indigo-600",
  },
  plus_que_parfait: {
    english: "Past Perfect",
    french: "Plus que Parfait",
    color: "bg-orange-600",
  },
};
